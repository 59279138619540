body{
    padding:0px 0px 0px 0px;
    z-index:0;
    overflow-x: hidden;

}
.navbar-dark {
    background-color: #ffffff;
}
#link{
    color: black;
}
.container button{
    background-color: #ffffffec;
}
.container button:hover{
    background-color: rgba(231, 62, 0, 0.678);
}
.row-header{
    margin:0px auto;
    padding:0px;
}
.light{
    color: black;
}
.dark{color: white;}
.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

.jumbotron {
    padding:70px 30px 70px 30px;
    margin:0px auto;
    background: #9575CD ;
    color:floralwhite;
}
#change{
    width: fit-content;
    animation: typing 0.7s infinite ease;
    color: rgb(255, 0, 0);
    min-height: 100px;
}
.changewrap{
    min-height: 100px;
    min-width: 180px;
}
.Hii{
    font-size: 25px;
}
.name{
    font-size: 22px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}
#Circle{
    float: left;
    margin-left: -300px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
#Circle2{
    float: right;
    margin-right: -300px;
}
.navlinkh:hover{
    text-decoration: none;
    color: rgb(255, 0, 0);
}
.navlinkh{
    text-decoration: none;
    margin: 9px;
    color: rgb(170, 96, 255);
    font-family:URW Chancery L, cursive;
    text-transform: uppercase;
}
#whitee{
    background-color: white;
}
#darkk{
    background-color: rgb(58, 9, 114);
}
.Programming{
    margin-top: 50px;
    width: 100%;
}
.circlenew{
    position: absolute;
    top: 350px;
    width: 200px;
    height: 200px;
    background-image: url('../images/circlenew.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 100;
}
.backimage{
    background-image: url('../images/backimage.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width:calc(100% - 40px);
    height: 400px;  
    z-index: -100;
    position: absolute;
}
#circlesmall{
    width: 100px;
    height: 100px;
    right: 10px;
}
#circlemed{
    top: 500px;
    right: 50%;
    width: 40px;
    height: 40px;
}
.content{
    margin: 50px;
}
#about_{
    background-color: rgba(178, 148, 202, 0.5);
    align-content: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding: 60px;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
    box-sizing: content-box;
}
#about__{
    box-sizing: content-box;
    border-right: 4px solid rgb(72, 255, 0);
    animation: typing 0.7s infinite ease;
}
.do{
    margin-top: 50px;
    align-content: center;
    text-align: center;
}
.about-img{
    float: left;
}
.about-img img {
    border-radius: 100%;
}
.about-text{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.modal-header{
    background-color:  #7432e769;
}
.footer{
    clear: both;
}
.modal-footer{
    background-color: lightcyan;
}
#aboutmodel{
    background-color: khaki;
}
.icon-footer-row{
    text-align: center;
}
#i11:hover{
    color: lawngreen;

}
.cards_{
    align-content: center;
}
.box_{
    width: 280px;
    height: 350px;
    text-align: center;
    overflow: hidden;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    box-shadow: 0 1px 3px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transition: color 0.5s linear; /* vendorless fallback */
    -o-transition: color 0.5s linear; /* opera */
    -ms-transition: color 0.5s linear; /* IE 10 */
    -moz-transition: color 0.5s linear; /* Firefox */
    -webkit-transition: box-shadow 0.5s linear;/*safari and chrome */
    box-sizing: border-box;
    line-height: 2px;
}
.box_:hover{
    box-shadow: 3px 3px 4px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

}
.box_ h3{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 25;
}
.quotes{
    text-align: center;
    align-content: center;
    height: 300px;
    padding: 100px;
    margin-bottom: 20px;
}
.quotes span{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 25px;
}
.image {
    position: absolute;
    margin-left: -50px;
}
.hire{
    text-align: center;
    align-content: center;
    background-color: rgba(12, 233, 30, 0.712);
    height: 80px;
    margin-bottom: 10px;
    box-sizing: content-box;
}
.hire h5{
    float: left;
    padding: 25px;
}
.hire a{
    float: right;
    margin-top: 20px;
    margin-right: 8%;
}
.footer{
    background-color: #D1C4E9;
    margin:0px auto;
    padding: 20px 0px 20px 0px;
}
#social_:hover{
    background-color: rgb(46, 165, 42);
}
@keyframes typing{
    0%{
        border-right: 3px solid rgba(0,0,0,0);
    }
    100%{
        border-right: 3px solid rgb(0, 255, 136);
    }
}

#button_:hover{
    box-shadow: 3px 3px 4px 5px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.23);
}
.feedback_{
    margin: 20px;
    box-sizing: content-box;
    border: 2px solid black;
}
.mode{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.mode img{
    border-radius: 100%;
}
@media only screen and (max-width: 660px) {

    .circlenew{
        display: none;
    }
    
}
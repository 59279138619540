.name_skill{
    text-align: center;
    margin-top: 150px;
    z-index: 100;
}
.skills{
    z-index: 100;
    text-align: center;
    
}
.skills_icon{
    margin-top: 20px;
    margin-left: 4px;
    margin-right: 4px;
    align-content: center;
    text-align: center;
}
.skills_icon:hover{
    color: rgb(98, 0, 255);
    font-size: 16px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.skills_icon img:hover{
    filter: saturate(3);
    filter: sepia(100%);
}
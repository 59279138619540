html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0 0 100px;
    /* bottom = footer height */

}
.footercon{

    box-shadow: 0px 0px 10px black;
    background-color: rgb(204, 167, 238);
    height: 120px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    overflow: hidden;
}
.rows{
    width: 100%;
}
.Copyright{
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
}
.address{
    font-weight: 500;
}
#links{
    text-decoration: none;
    color: black;
}
#links:hover{
    color: red;
}
.navlink:hover{
    text-decoration: none;
}
.img_robo{
    width: 95%;
    color: rgb(113, 123, 133);
}
.social_links{
    text-decoration: none;
    padding: 10px 2px;

}
.social_links:hover{
    text-decoration: none;
}

#textarea{
    height: 100px;
}
.submit_btn{
    background-color: red;
}

